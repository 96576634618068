.section-quem-somos-individual{
    padding: 4rem 0rem 8rem 0rem;
    background-color: #fff;

    @media(max-width: 1199px){
        padding: 4rem 0rem;
    }

    .prev-fotos-sobre {
        position: absolute;
        bottom: -50px;
        right: 65px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;
        z-index: 2;

        @media(min-width: 992px) and (max-width: 1199px){
            bottom: -50px;
        }

        @media(max-width: 991px){
            left: calc(50% - 40px);
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .next-fotos-sobre {
        position: absolute;
        bottom: -50px;
        right: 15px;
        cursor: pointer;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;
        z-index: 2;

        @media(max-width: 991px){
            right: calc(50% - 40px);
        }

        @media(min-width: 992px) and (max-width: 1199px){
            bottom: -50px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .slide-wrapper-container-fotos{
        position: relative;
        width: 100%;

        .foto-sobre{
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 28.32rem;
            transition: all 0.5s ease;
            will-change: transform;
            cursor: pointer;

            @media(min-width: 1200px) and (max-width: 1599px){
                padding-top: 25rem;
            }

            @media(min-width: 992px) and (max-width: 1199px){
                padding-top: 23rem;
            }

            @media(max-width: 991px){
                padding-top: 20rem;
            }

            &:hover, &:focus{
                transform: scale(1.02);
                transition: all 0.5s ease;
            }
        }

        .foto-tras{
            z-index: 1;
            //margin: 0rem -5rem 0rem 0rem;

            &:hover, &:focus{
                z-index: 3;
                transition: all 0.5s ease;
            }
        }

        .foto-frente{
            z-index: 2;
            //margin: 14rem 0rem 0rem -5rem;

            &:hover, &:focus{
                z-index: 3;
                transition: all 0.5s ease;
            }
        }
    }
    
    .box-conteudo-institucional{

        @media(max-width: 991px){
            margin-top: 7rem;
        }

        .barra-amarela-institucional{
            background-color: #FFCB06;
            width: 24px;
            height: 8px;
            border-radius: 2.6px;
            margin-bottom: 0.75rem;
        }

        .titulo-institucional{
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: left;
            color: #111431;
            margin-bottom: 1.5rem;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: left;
                color: #FFCB06;
            }
        }

        .conteudo-institucional{
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: 33px;
            letter-spacing: 0em;
            text-align: left;
            color: #111431;
        }
    }
}