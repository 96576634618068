.footer {
    background-color: #BF1E23;
    padding: 50px 0px;
    position: relative;
    overflow: hidden;
    .tomada {
        background-image: url('/images/tomada.png');
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 300px;
        height: 250px;
        right: 15px;
        bottom: -15px;
        z-index: 2;
    }
    @media(max-width: 991px) {
        padding: 50px 0px 0px 0px;
    }
    .logo {
        will-change: transform;
        transition: all 0.5s ease;
        max-width: 303px;
        &:hover,
        &:focus {
            transition: all 0.5s ease;
            transform: scale(1.01);
        }
    }
    .entre-em-contato-rodape {
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin-bottom: 2rem;
        @media(max-width: 991px) {
            margin-top: 2rem;
            margin-bottom: 1rem;
            text-align: center;
        }
        img {
            width: 8.15px;
        }
    }
    .item-rodape {
        font-family: "Poppins", sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        transition: all 0.5s ease;
        will-change: transform;
        margin-bottom: 1rem;
        @media(max-width: 991px) {
            text-align: center;
        }
        img {
            width: 15px;
        }
        &:hover,
        &:focus {
            transform: translateY(-1px);
            transition: all 0.5s ease;
        }
        a {
            text-decoration: none;
            color: #fff;
        }
    }
    .iframe-rodape {
        border: 0;
        @media(max-width: 991px) {
            margin-bottom: 1rem;
            margin-top: 1rem;
        }
    }
}

.footer-2 {
    background-color: #B70205;
    .texto-copyright {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        padding: 11px 0px;
        .grupo-mvl {
            color: #fff;
            transition: all 0.5s ease;
            &:hover,
            &:focus {
                color: #111431;
                text-decoration: none !important;
                transition: all 0.5s ease;
            }
        }
    }
}

.redes-sociais-amarelas {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 991px) {
        display: block;
        text-align: center;
        margin: 0px 0px 0.5rem 0px;
        height: auto;
    }
    a {
        color: #FFCB06;
        font-size: 24px;
        transition: all 0.5s ease;
        margin-left: 1rem;
        @media(max-width: 991px) {
            margin: 0rem 0.5rem;
        }
        &:hover,
        &:focus {
            color: #111431;
            transition: all 0.5s ease;
        }
    }
}

.botao-whatsapp {
    position: fixed;
    right: 15px;
    bottom: 10px;
    z-index: 3;
    opacity: 0;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    @media(max-width: 991px) {
        width: 50px;
        height: 50px;
        bottom: 0px;
    }
    a {
        margin: 5px 0px;
        color: #fff;
        transition: all 0.5s ease;
        border-radius: 48px;
        background-color: #26D367;
        border: 1px #26D367 solid;
        font-size: 15px;
        line-height: 16px;
        padding: 10px 25px;
        text-decoration: none !important;
        @media(max-width: 991px) {
            border-radius: 50% !important;
            font-size: 26px !important;
            padding: 8.5px 12.63px !important;
            text-align: center !important;
        }
        &:hover,
        &:focus {
            text-decoration: none !important;
            background-color: #fff;
            color: #26D367;
            transition: all 0.5s ease;
            i {
                color: #26D367;
                transition: all 0.5s ease;
            }
        }
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}


.footer-lgpd {
    position: fixed;
    z-index: 99999;
    max-width: 1230px;
    transition: 0.3s ease-in-out;
    background: #fff;
    bottom: 25px;
    left: 10px;
    right: 10px;
    padding: 20px;
    box-shadow: 0 0 10px #aaa;
    border-radius: 12px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
}
.footer-lgpd-opened {
    transition: 0.1s ease-in-out;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0);
}
.btn-lgpd {
    background-color: #bf1e23;
    color: #fff !important;
    text-align: center;
    border: 1px #bf1e23 solid;
    transition: all 0.5s ease;
    font-weight: 500;
    width: 300px;
    padding: 10px 15px;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1px;
    border-radius: 1.25rem;
    text-decoration: none !important;
}
.btn-lgpd:hover,
.btn-lgpd:focus {
    background-color: white !important;
    color: #bf1e23 !important;
    transition: all 0.5s ease;
}
.label-lgpd {
    color: white;
}
