.container-banner {
    position: relative;

    .mascara-vermelha{
        position: absolute;
        background-color: #BF1E23;
        opacity: 0.9;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 45%;

        @media(max-width: 991px){
            width: 100%;
        }
    }

    .detalhe-amarelo{
        background-color: #FFCB06;
        width: 8px;
        right: 0px;
        z-index: 4;
        left: calc(45% - 4px);
        height: 319px;
        top: calc(50% - 159.5px);
        position: absolute;

        @media(max-width: 991px){
            display: none;
        }
    }

    .descricao-banner {
        position: absolute;
        top: calc(50% - 72px);
        left: 5rem;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        width: 50%;
        overflow: visible;
        opacity: 0;
        transform: translateY(80px);
        transition: all 0.5s ease;
        color: #fff;
        z-index: 2;

        span{
            font-family: "Poppins", sans-serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 67px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;
        }

        @media(max-width: 991px){
            height: auto;
            font-size: 25px !important;
            width: 100%;
            line-height: 28px;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;
            left: 0;
            font-size: 25px;
            top: calc(50% - 130px);
            padding-left: 30px;
            padding-right: 30px;
            max-height: 250px;
            overflow: hidden !important;

            span{
                font-size: 35px;
                line-height: 38px;
            }
        }

        @media(min-width: 992px) and (max-width: 1199px) {
            width: 42%;
            left: 1rem;
        }

        @media(min-width: 1200px) and (max-width: 1599px){
            left: 3rem;
            width: 40%;
            padding-left: 5rem;
        }

        @media(min-width: 1600px) {
            left: 5rem;
            width: 38%;
            padding-left: 8rem;
        }

        .link-descricao{
            margin-top: 2rem;

            a{
                color: #FFCB06;
                border: 1px #FFCB06 solid;
                border-radius: 6px;
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                padding: 0.85rem 1.44rem;
                transition: all 0.5s ease;

                &:hover, &:focus{
                    transition: all 0.5s ease;
                    background-color: #FFCB0620;
                }

                img{
                    width: 21px;
                    height: 10.17px;
                    margin-left: 5px;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .imagem {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        height: calc(100vh - 188px);

        @media(max-width: 991px){
            height: 300px;
        }
    }

    .tns-nav{
        position: absolute;
        left: 90%;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        z-index: 1;

        @media(max-width: 991px){
            width: 100%;
            bottom: 5%;
            flex-direction: row;
            left: 0px;
            right: 0px;
        }

        button{
            width: 17.76px;
            height: 17.76px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #fff;
            border: 1px #fff solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover, &:focus{
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active{
            background-color: transparent;
            border: 1px #fff solid !important;

            &:before{
                content: "";
                width: 5.63px;
                height: 5.63px;
                border-radius: 100%;
                background-color: #fff;
                left: calc(50% - 2.815px);
                top: calc(50% - 2.815px);
                position: absolute;
            }
        }
    }
}

.section-categorias-produtos{
    margin-top: -3rem;
    background-color: #F5F5F5;

    @media(max-width: 991px){
        margin-top: 1.5rem;
    }

    .box-categoria-produto{
        transition: all 0.5s ease;
        will-change: transform;
        background-color: #fff;
        padding: 2rem;
        position: relative;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
        z-index: 1;

        @media(max-width: 991px){
            margin-bottom: 2rem;
        }

        &:hover, &:focus{
            transform: scale(1.02);
            transition: all 0.5s ease;
            z-index: 2;

            .ver-mais-categoria{
                color: #111431;
                transition: all 0.5s ease;

                .img-categoria-ver-mais{
                    transition: all 0.5s ease;
                    display: none;
                }

                .img-categoria-ver-mais-2{
                    transition: all 0.5s ease;
                    display: inline-block;
                }
            }

            .detalhe-vermelho{
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .imagem-categoria{
            text-align: center;
            transition: all 0.5s ease;

            img{
                max-width: 100px;
            }
        }

        .title-categoria{
            font-family: "Poppins", sans-serif;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            margin: 1rem 0rem;

            @media(min-width: 992px) and (max-width: 1199px){
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }
        }

        .ver-mais-categoria{
            margin-top: 0.5rem;
            transition: all 0.5s ease;
            font-family: "Poppins", sans-serif;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: #BF1E23;

            .img-categoria-ver-mais{
                margin-left: 5px;
                width: 23px;
                transition: all 0.5s ease;
                will-change: transform;
            }

            .img-categoria-ver-mais-2{
                margin-left: 5px;
                width: 23px;
                display: none;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .detalhe-vermelho{
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img{
                width: 17.04px;
                height: 17.25px;
            }
        }
    }

    .prev-categorias {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            left: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .next-categorias {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            right: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }
}

.section-produtos-home{
    background-color: #F5F5F5;
    padding-bottom: 2rem;

    .title-section{
        padding: 4rem 0rem;

        .detalhe{
            border-radius: 2.66667px;
            background-color: #BF1E23;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title{
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
                color: #BF1E23;
            }
        }
    }

    .card-produto{
        border: 1px #E6E6E6 solid;
        position: relative;

        &:hover, &:focus{

            .box-absolute-produto{
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-categoria{
                margin-top: 0.5rem;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-produto{
                margin-bottom: 1.5rem;
                transition: all 0.5s ease;
            }
        }

        .box-absolute-produto{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            background-color: #BF1E23;
            position: absolute;
            width: 100%;
            bottom: 0;
            color: #FFCB06;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;
            opacity: 0;
            transition: all 0.5s ease;

            @media(max-width: 991px){
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            img{
                width: 23.1px;
                height: 11.19px;
                margin-left: 5px;
            }
        }

        .box-foto-produto{
            padding: 1rem;
            background-color: #fff;

            .foto-produto{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
            }
        }

        .conteudo-produto{
            padding: 2rem;
            background-color: #fff;

            .barra-cinza{
                border-top: 1px #E6E6E6 solid;
            }

            .titulo-categoria{
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #737373;
                margin: 2rem 0rem 0.5rem 0rem;
                transition: all 0.5s ease;

                @media(max-width: 991px){
                    margin-top: 0.5rem;
                    transition: all 0.5s ease;
                }
            }

            .titulo-produto{
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;

                @media(max-width: 991px){
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .tns-nav{
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        button{
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover, &:focus{
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active{
            background-color: transparent;
            border: 1px #BF1E23 solid !important;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
            background-color: #BF1E23;
        }
    }

    .prev-produtos {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            left: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .next-produtos {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            right: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .ver-mais-produtos{
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            border: 1px #111431 solid;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;

            &:hover, &:focus{
                background-color: #BF1E23;
                transition: all 0.5s ease;
            }

            img{
                width: 21px;
                margin-left: 5px;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;
                padding-right: calc(2.2rem - 5px);

                img{
                    margin-left: 10px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}

.section-sobre-home{
    background-color: #F5F5F5;
    padding: 4rem 0rem;

    .box-sobre-home{
        background-color: #BF1E23;

        .conteudo-sobre-home{
            padding: 5rem 2rem 5rem 12rem;

            @media(max-width: 991px){
                padding: 5rem 2rem;
            }

            @media(min-width: 992px) and (max-width: 1365px){
                padding: 5rem 2rem 5rem 5rem;
            }

            .detalhe-amarelo{
                background-color: #FFCB06;
                width: 24px;
                height: 8px;
                border-radius: 2.66667px;
            }

            .titulo-sobre-home{
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 300;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin-top: 1rem;

                span{
                    font-family: "Poppins", sans-serif;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 41px;
                    letter-spacing: 0em;
                    text-align: left;
                    color: #FFCB06;
                }
            }

            .texto-sobre-home{
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin: 1.5rem 0rem 2.8rem 0rem;
            }

            .ver-mais-sobre-home{
                a{
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #111431;
                    border: 1px #111431 solid;
                    border-radius: 6px;
                    padding: 0.85rem 2.2rem;
                    transition: all 0.5s ease;

                    img{
                        width: 21px;
                        margin-left: 5px;
                        transition: all 0.5s ease;
                    }

                    &:hover, &:focus{
                        background-color: #ffcb06;
                        transition: all 0.5s ease;
                        padding-right: calc(2.2rem - 5px);

                        img{
                            margin-left: 10px;
                            transition: all 0.5s ease;
                        }
                    }
                }
            }
        }
    }

    .foto-sobre-home{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        @media(max-width: 991px){
            padding-top: 20rem;
        }
    }
}

.section-dados-sobre{
    background-color: #F5F5F5;
    margin-top: -130px;

    @media(max-width: 991px){
        margin-top: 0;
        margin-bottom: 1rem;
    }

    @media(min-width:992px) and (max-width:1599px){
        margin-top: -105px;
    }

    .box-item-dado{
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        height: 183px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0.5px #33333310 solid;
        position: relative;
        transition: all 0.5s ease;
        will-change: transform;

        &:hover, &:focus{
            transform: scale(1.02);
            transition: all 0.5s ease;

            .detalhe-vermelho{
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .detalhe-vermelho{
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img{
                width: 17.04px;
                height: 17.25px;
            }
        }

        .imagem-dado{
            text-align: center;
            margin-bottom: 0.75rem;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 56px;
            }
        }

        .texto-dado{
            font-family: "Poppins", sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #111431;
            }
        }
    }
}

.section-fornecedores-home{
    padding-bottom: 5rem;
    background-color: #F5F5F5;

    @media(max-width: 991px){
        padding-bottom: 4rem;
    }

    .title-section{
        padding: 4rem 0rem;

        .detalhe{
            border-radius: 2.66667px;
            background-color: #BF1E23;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title{
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
                color: #BF1E23;
            }
        }
    }

    .box-logo-fornecedor{
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 2.3rem;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;

        @media(max-width: 991px){
            margin: 0px 3rem;
        }

        img{
            max-height: 100%;
        }

        &:hover, &:focus{
            transform: scale(1.01);
            transition: all 0.5s ease;
        }
    }

    .tns-nav{
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        @media(max-width: 991px){
            display: none !important;
        }

        button{
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover, &:focus{
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active{
            background-color: transparent;
            border: 1px #BF1E23 solid !important;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
            background-color: #BF1E23;
        }
    }

    .prev-fornecedores {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            left: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .next-fornecedores {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            right: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }
}
