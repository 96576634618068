.section-categorias-produtos-individual{
    padding-top: 1rem;
    background-color: #F5F5F5;

    .prev-categorias {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            left: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .next-categorias {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #F5F5F5;
        border: 0.75px solid #111431;
        transition: all 0.5s ease;

        @media(max-width: 991px){
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media(min-width: 992px) and (max-width: 1199px){
            right: -10px;
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #BF1E23;
            transition: all 0.5s ease;
        }

        img{
            width: 13.54px;
            height: 13.54px;
        }
    }

    .box-categoria-produto{
        transition: all 0.5s ease;
        will-change: transform;
        background-color: #fff;
        padding: 2rem;
        position: relative;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
        z-index: 1;

        @media(max-width: 991px){
            margin-bottom: 2rem;
        }

        &:hover, &:focus{
            transform: scale(1.02);
            transition: all 0.5s ease;
            z-index: 2;

            .ver-mais-categoria{
                color: #111431;
                transition: all 0.5s ease;

                .img-categoria-ver-mais{
                    transition: all 0.5s ease;
                    display: none;
                }

                .img-categoria-ver-mais-2{
                    transition: all 0.5s ease;
                    display: inline-block;
                }
            }

            .detalhe-vermelho{
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .imagem-categoria{
            text-align: center;
            transition: all 0.5s ease;

            img{
                max-width: 100px;
            }
        }

        .title-categoria{
            font-family: "Poppins", sans-serif;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            margin: 1rem 0rem;

            @media(min-width: 992px) and (max-width: 1199px){
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }
        }

        .ver-mais-categoria{
            margin-top: 0.5rem;
            transition: all 0.5s ease;
            font-family: "Poppins", sans-serif;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: #BF1E23;

            .img-categoria-ver-mais{
                margin-left: 5px;
                width: 23px;
                transition: all 0.5s ease;
                will-change: transform;
            }

            .img-categoria-ver-mais-2{
                margin-left: 5px;
                width: 23px;
                display: none;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .detalhe-vermelho{
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img{
                width: 17.04px;
                height: 17.25px;
            }
        }
    }

    .box-categoria-produto-active{
        transition: all 0.5s ease;
        will-change: transform;
        background-color: #fff;
        padding: 2rem;
        position: relative;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
        transform: scale(1.02);
        transition: all 0.5s ease;
        z-index: 2;

        @media(max-width: 991px){
            margin-bottom: 2rem;
        }

        .imagem-categoria{
            text-align: center;
            transition: all 0.5s ease;

            img{
                max-width: 100px;
            }
        }

        .title-categoria{
            font-family: "Poppins", sans-serif;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            margin: 1rem 0rem;
        }

        .ver-mais-categoria{
            margin-top: 0.5rem;
            transition: all 0.5s ease;
            font-family: "Poppins", sans-serif;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            transition: all 0.5s ease;

            .img-categoria-ver-mais{
                margin-left: 5px;
                width: 23px;
                will-change: transform;
                transition: all 0.5s ease;
                display: none;
            }

            .img-categoria-ver-mais-2{
                margin-left: 5px;
                width: 23px;
                display: none;
                will-change: transform;
                transition: all 0.5s ease;
                display: inline-block;
            }
        }

        .detalhe-vermelho{
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            opacity: 1;
            transition: all 0.5s ease;

            img{
                width: 17.04px;
                height: 17.25px;
            }
        }
    }
}

.section-produtos{
    background-color: #F5F5F5;
    padding-bottom: 2rem;

    .card-produto{
        margin-bottom: 2rem;
        border: 1px #E6E6E6 solid;
        position: relative;

        &:hover, &:focus{

            .box-absolute-produto{
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-categoria{
                margin-top: 0.5rem;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-produto{
                margin-bottom: 1.5rem;
                transition: all 0.5s ease;
            }
        }

        .box-absolute-produto{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            background-color: #BF1E23;
            position: absolute;
            width: 100%;
            bottom: 0;
            color: #FFCB06;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;
            opacity: 0;
            transition: all 0.5s ease;

            @media(max-width: 991px){
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            img{
                width: 23.1px;
                height: 11.19px;
                margin-left: 5px;
            }
        }

        .box-foto-produto{
            padding: 1rem;
            background-color: #fff;

            .foto-produto{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
            }
        }

        .conteudo-produto{
            padding: 2rem;
            background-color: #fff;

            .barra-cinza{
                border-top: 1px #E6E6E6 solid;
            }

            .titulo-categoria{
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #737373;
                margin: 2rem 0rem 0.5rem 0rem;
                transition: all 0.5s ease;

                @media(max-width: 991px){
                    margin-top: 0.5rem;
                    transition: all 0.5s ease;
                }
            }

            .titulo-produto{
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;

                @media(max-width: 991px){
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .ver-mais-produtos{
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            border: 1px #111431 solid;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;

            img{
                width: 21px;
                margin-left: 5px;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;
                padding-right: calc(2.2rem - 5px);

                img{
                    margin-left: 10px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}
