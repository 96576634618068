.section-descricao-detalhes-produto{
    padding: 50px 0px;

    .nav-tabs{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;

        .active{
            color: #000;
            transition: all 0.5s ease;

            &:before{
                content: "";
                transition: all 0.5s ease;
                position: absolute;
                width: 100%;
                bottom: 0px;
                left: 0px;
                height: 3px;
                border-radius: 6px;
                background-color: #B4001F;
            }
        }
    }

    .nav-item{
        position: relative;
        border: none;
        color: #333333;
        font-size: 25px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        transition: all 0.5s ease;

        @media(max-width: 540px){
            font-size: 18px;
        }
    }

    .tab-pane{
        color: #5F5F5F;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        border: 1px solid #C4C4C47D;
        padding: 37px 50px;

        @media(max-width: 540px){
            padding: 15px;
        }
    }
}

.section-produtos-relacionados{
    padding: 50px 0px;
    position: relative;

    .titulo-produtos-relacionados{
        color: #333333;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        position: relative;
        margin-bottom: 60px;

        span{
            font-size: 40px;
            line-height: 47px;
            font-weight: 700;
        }

        &:before{
            position: absolute;
            content: "";
            width: 40px;
            height: 1px;
            background-color: #FF0232;
            bottom: -15px;
            left: calc(50% - 20px);
        }
    }

    .card-produto-home{
        border-radius: 6.27834px;
        background-color: #fff;
        margin-bottom: 30px;
        border: 0.84916px solid #fff;
        transition: all 0.5s ease;
        padding: 15px 10px;

        &:hover, &:focus{
            border: 0.84916px solid #FF0232;
            transition: all 0.5s ease;

            .imagem-produtos-home{
                border-top: 0.811514px #fff solid;
                border-left: 0.811514px #fff solid;
                border-right: 0.811514px #fff solid;
                border-bottom: 1px #FF0232 solid;
                border-radius: 0px;
                transition: all 0.5s ease;
            }

            .ver-mais-produto{
                a{
                    text-decoration: none !important;
                    color: #FF0232;
                    transition: all 0.5s ease;
                }
            }
        }

        .imagem-produtos-home{
            height: 250px;
            border-radius: 6.27834px;
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            border: 0.811514px solid rgba(196, 196, 196, 0.53);
            transition: all 0.5s ease;
        }

        .titulo-produto-home{
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            height: 42px;
            overflow: hidden;
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 15px;
            color: #333333;
        }

        .categoria-produto-home{
            margin: 5px 0px;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            height: 18px;
            overflow: hidden;
            color: #8C8C8C;
        }

        .ver-mais-produto{
            a{
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;
                color: #333333;
                transition: all 0.5s ease;

                img{
                    width: 13px;
                }
            }
        }
    }

    .prev-produtos {
        position: absolute;
        top: calc(50% + 50px);
        left: 0px;
        cursor: pointer;
        color: #FF0232;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #fff;
        border: 1px #FF0232 solid;
        transition: all 0.5s ease;

        @media(max-width: 767px){
            width: 30px;
            height: 30px;
            left: 30px;
            top: calc(50% + 30px);

            i{
                font-size: 20px !important;
            }
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #FF0232;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }

    .next-produtos {
        position: absolute;
        top: calc(50% + 50px);
        right: 0px;
        cursor: pointer;
        color: #FF0232;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #fff;
        border: 1px #FF0232 solid;
        transition: all 0.5s ease;

        @media(max-width: 767px){
            width: 30px;
            height: 30px;
            right: 30px;
            top: calc(50% + 30px);

            i{
                font-size: 20px !important;
            }
        }

        &:hover, &:focus {
            color: #fff;
            background-color: #FF0232;
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }
}
